<template>
  <div>
    <div class="ma-2 d-flex justify-space-between" v-if="isMobile">
      <v-autocomplete
        class="ma-2"
        label="Filter by product"
        placeholder="All Products"
        hide-details
        clearable
        @click.prevent.stop=""
        v-model="productFilter"
        :items="products"
        @change="filter"
      />
      <v-autocomplete
        class="ma-2"
        label="Filter by process"
        placeholder="All Processes"
        hide-details
        clearable
        @click.prevent.stop=""
        v-model="processFilter"
        :items="processes"
        @change="filter"
      />
    </div>
    <v-divider/>
    <v-data-table
      class="inventory-details"
      :headers="headers"
      :items="filteredEventList"
      item-key="name"
    >
      <template v-slot:header.inventoryId="{}">
        <v-autocomplete
          label="Product"
          placeholder="Filter by product"
          hide-details
          dense
          clearable
          @click.prevent.stop=""
          v-model="productFilter"
          :items="products"
          @change="filter"
        />
      </template>
      <template v-slot:header.processId="{}">
        <v-autocomplete
          label="Process"
          placeholder="Filter by process"
          hide-details
          dense
          clearable
          @click.prevent.stop=""
          v-model="processFilter"
          :items="processes"
          @change="filter"
        />
      </template>
      <template v-slot:item.inventoryId="{ item }">
        <a
          target="_blank"
          :href="`https://prod.${domain}.com/admin/inventory/${item.inventoryId}`"
        >
          {{ item.product }}
        </a>
      </template>
      <template v-slot:item.processId="{ item }">
        <a
          target="_blank"
          :href="`https://prod.${domain}.com/factory/process/${item.processId}`"
          >{{ item.processCode }}</a
        >
      </template>
      <template v-slot:item.time="{ item }">
        {{ item.time | formatDateTime }}
      </template>
      <template v-slot:item.quantity="{ item }">
        {{ item.quantity | formatNumber }}
      </template>
      <template v-slot:item.value="{ item }">
        <div v-if="item.costAdjustment != 0">
          <v-menu bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <span class="red--text">{{ item.value | currency }}</span>
              </div>
            </template>
            <v-list>
              <v-layout column style="height: 60vh">
                <v-flex style="overflow: auto">
                  <v-simple-table class="ma-4">
                    <thead>
                      <tr>
                        <th colspan="2" class="text-center">
                          Cost Adjustments
                        </th>
                      </tr>
                      <tr>
                        <th>Amount</th>
                        <th>Notes</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(adj, index) in getCostAdjustments(
                          item.inventoryId,
                          date
                        )"
                        :key="index"
                      >
                        <td>{{ adj.amount | currency }}</td>
                        <td>
                          <div class="ma-2">{{ adj.notes }}</div>
                          <div class="text-caption text-right ma-2">
                            {{ adj.adjustmentTime | formatDateYear }}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-flex></v-layout
              >
            </v-list>
          </v-menu>
        </div>
        <div v-else>
          {{ item.value | currency }}
        </div>
      </template>

      <template v-slot:item.unitCost="{ item }">
        {{ item.unitCost | currency }}
      </template>

      <template v-if="summary" v-slot:body.append="{ }">
        <tr>
          <td colspan="3">Total</td>
          <td>
            {{ summary.quantity | formatNumber }}
          </td>
          <td>
            {{ summary.value | currency }}
          </td>
          <td>
            <span v-if="summary.quantity && summary.quantity != 0">
             {{ summary.value / summary.quantity | currency }}
            </span>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    eventList: {
      type: Array,
      required: true,
      default: () => [],
    },
    eventType: {
      type: String,
      required: false,
      default: "",
    },
    domain: {
      type: String,
      required: false,
      default: "localhost",
    },
    date: {
      type: Date,
    },
  },
  computed: {
    ...mapGetters("inventoryCostAdj", ["costAdjustmentService"]),
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
  },
  data: function () {
    return {
      headers: [
        { text: "Inventory ID", value: "inventoryId" },
        { text: "Process", value: "processId" },
        { text: "Time", value: "time" },
        { text: "Quantity", value: "quantity" },
        { text: "Cost", value: "value" },
        { text: "Unit Cost", value: "unitCost" },
      ],
      summary: null,
      filteredEventList: [],
      productFilter: null,
      products: [],
      processFilter: null,
      processes: [],
    };
  },
  mounted() {
    this.filteredEventList = this.eventList;
    this.summarize();
  },
  methods: {
    getCostAdjustments(inventoryId, balanceDate) {
      return this.costAdjustmentService.getCostAdjustmentDetails(
        inventoryId,
        balanceDate
      );
    },
    filter() {
      this.filteredEventList = this.eventList;

      if (this.productFilter && this.productFilter.trim() !== "") {
        this.filteredEventList = this.filteredEventList.filter(
          (e) => e.product == this.productFilter
        );
      }
      if (this.processFilter && this.processFilter.trim() !== "") {
        this.filteredEventList = this.filteredEventList.filter(
          (e) => e.processCode == this.processFilter
        );
      }

      this.summarize();
    },
    summarize() {
      const products = new Set();
      const processes = new Set();
      this.summary = this.filteredEventList.reduce((group, row) => {
        products.add(row.product);
        processes.add(row.processCode);

        if (group) {
          group.value += row.value;
          group.quantity += row.quantity;
        } else {
          group = {
            value: row.value,
            quantity: row.quantity,
          };
        }

        return group;
      }, null);
      this.products = [...products];
      this.processes = [...processes];
    },
  },
};
</script>

<style scoped>
.inventory-details thead.v-data-table-header > tr > th > div {
  display: inline-block;
  width: 80%;
}
</style>
