var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isMobile)?_c('div',{staticClass:"ma-2 d-flex justify-space-between"},[_c('v-autocomplete',{staticClass:"ma-2",attrs:{"label":"Filter by product","placeholder":"All Products","hide-details":"","clearable":"","items":_vm.products},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();},"change":_vm.filter},model:{value:(_vm.productFilter),callback:function ($$v) {_vm.productFilter=$$v},expression:"productFilter"}}),_c('v-autocomplete',{staticClass:"ma-2",attrs:{"label":"Filter by process","placeholder":"All Processes","hide-details":"","clearable":"","items":_vm.processes},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();},"change":_vm.filter},model:{value:(_vm.processFilter),callback:function ($$v) {_vm.processFilter=$$v},expression:"processFilter"}})],1):_vm._e(),_c('v-divider'),_c('v-data-table',{staticClass:"inventory-details",attrs:{"headers":_vm.headers,"items":_vm.filteredEventList,"item-key":"name"},scopedSlots:_vm._u([{key:"header.inventoryId",fn:function(ref){return [_c('v-autocomplete',{attrs:{"label":"Product","placeholder":"Filter by product","hide-details":"","dense":"","clearable":"","items":_vm.products},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();},"change":_vm.filter},model:{value:(_vm.productFilter),callback:function ($$v) {_vm.productFilter=$$v},expression:"productFilter"}})]}},{key:"header.processId",fn:function(ref){return [_c('v-autocomplete',{attrs:{"label":"Process","placeholder":"Filter by process","hide-details":"","dense":"","clearable":"","items":_vm.processes},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();},"change":_vm.filter},model:{value:(_vm.processFilter),callback:function ($$v) {_vm.processFilter=$$v},expression:"processFilter"}})]}},{key:"item.inventoryId",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":("https://prod." + _vm.domain + ".com/admin/inventory/" + (item.inventoryId))}},[_vm._v(" "+_vm._s(item.product)+" ")])]}},{key:"item.processId",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":("https://prod." + _vm.domain + ".com/factory/process/" + (item.processId))}},[_vm._v(_vm._s(item.processCode))])]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.time))+" ")]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.quantity))+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [(item.costAdjustment != 0)?_c('div',[_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm._f("currency")(item.value)))])])]}}],null,true)},[_c('v-list',[_c('v-layout',{staticStyle:{"height":"60vh"},attrs:{"column":""}},[_c('v-flex',{staticStyle:{"overflow":"auto"}},[_c('v-simple-table',{staticClass:"ma-4"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center",attrs:{"colspan":"2"}},[_vm._v(" Cost Adjustments ")])]),_c('tr',[_c('th',[_vm._v("Amount")]),_c('th',[_vm._v("Notes")])])]),_c('tbody',_vm._l((_vm.getCostAdjustments(
                        item.inventoryId,
                        _vm.date
                      )),function(adj,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(_vm._f("currency")(adj.amount)))]),_c('td',[_c('div',{staticClass:"ma-2"},[_vm._v(_vm._s(adj.notes))]),_c('div',{staticClass:"text-caption text-right ma-2"},[_vm._v(" "+_vm._s(_vm._f("formatDateYear")(adj.adjustmentTime))+" ")])])])}),0)])],1)],1)],1)],1)],1):_c('div',[_vm._v(" "+_vm._s(_vm._f("currency")(item.value))+" ")])]}},{key:"item.unitCost",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.unitCost))+" ")]}},(_vm.summary)?{key:"body.append",fn:function(ref){return [_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_vm._v("Total")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.summary.quantity))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.summary.value))+" ")]),_c('td',[(_vm.summary.quantity && _vm.summary.quantity != 0)?_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.summary.value / _vm.summary.quantity))+" ")]):_vm._e()])])]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }